html,
body {
  height: 100%;
  margin: 0;
  // background: linear-gradient(#b1c8fc, rgba(217, 217, 217, 0));
  background: rgba(126, 147, 240, 0.15);
  background-repeat: no-repeat;
}

span,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: Poppins, "Helvetica Neue", sans-serif !important;
}
